// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-boundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 480px;
    margin: 5rem auto;
    gap: 1rem;
    padding: 2rem;
    background-color: white;
}

.error-boundary h1 {
    font-size: xx-large;
}

.error-boundary a {
    color: var(--mt-ui-primary);
    text-underline-offset: 0.2rem;
}

.error-boundary__illustration {
    margin: 2rem 0;
}

.error-boundary__illustration svg {
    width: 100%;
    max-height: 30vh;
}

.error-boundary button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: var(--mt-ui-primary);
    color: var(--mt-ui-white);
    cursor: pointer;
    font-weight: 700;
    transition: background-color 0.2s;
}

.error-boundary button:hover {
    background-color: var(--text-color);
}

@media screen and (max-width: 1024px) {
    .error-boundary {
        margin: 1rem auto;
    }
}

@media screen and (max-width: 768px) {
    .error-boundary {
        margin: 0 auto;
    }

    .error-boundary h1 {
        font-size: x-large;
        align-self: flex-start;
    }

    .error-boundary__illustration {
        margin: 1rem 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/features/error-boundary/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;IACT,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,6BAA6B;AACjC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,YAAY;IACZ,sBAAsB;IACtB,sCAAsC;IACtC,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,iCAAiC;AACrC;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,kBAAkB;QAClB,sBAAsB;IAC1B;;IAEA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".error-boundary {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    max-width: 480px;\n    margin: 5rem auto;\n    gap: 1rem;\n    padding: 2rem;\n    background-color: white;\n}\n\n.error-boundary h1 {\n    font-size: xx-large;\n}\n\n.error-boundary a {\n    color: var(--mt-ui-primary);\n    text-underline-offset: 0.2rem;\n}\n\n.error-boundary__illustration {\n    margin: 2rem 0;\n}\n\n.error-boundary__illustration svg {\n    width: 100%;\n    max-height: 30vh;\n}\n\n.error-boundary button {\n    padding: 0.5rem 1rem;\n    border: none;\n    border-radius: 0.25rem;\n    background-color: var(--mt-ui-primary);\n    color: var(--mt-ui-white);\n    cursor: pointer;\n    font-weight: 700;\n    transition: background-color 0.2s;\n}\n\n.error-boundary button:hover {\n    background-color: var(--text-color);\n}\n\n@media screen and (max-width: 1024px) {\n    .error-boundary {\n        margin: 1rem auto;\n    }\n}\n\n@media screen and (max-width: 768px) {\n    .error-boundary {\n        margin: 0 auto;\n    }\n\n    .error-boundary h1 {\n        font-size: x-large;\n        align-self: flex-start;\n    }\n\n    .error-boundary__illustration {\n        margin: 1rem 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
